<template>
  <div
    class="logout"
    @click="logout"
  >
    <feather-icon
      class="mr-25"
      icon="LogOutIcon"
    />
    <span>Logout</span>
  </div>
</template>
<style scoped>
@media (max-width: 640px) {
  .logout {
    display: none !important;
  }
}
.logout {
  position: absolute;
  bottom: 90px;
  background: #FFFFFF;
  right: 24px;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 20px;
  color: #1B1B1B;
}
</style>
<script>
export default {
  methods: {
    async logout() {
      this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)
      await this.$store.dispatch('user/logout')

      // Redirect to login page
      window.location.replace('/login/tps')
    },
  },
}
</script>
