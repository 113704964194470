<template>
  <div id="wrapper-mobile-size">
    <div class="mobile-size">
      <div id="detail-candidate">
        <div
          id="btn-back"
          class="position-fixed"
        >
          <b-button
            size="sm"
            variant="light"
            class="text-success"
            @click="$router.push('/candidates')"
          >
            <feather-icon
              icon="ChevronLeftIcon"
            />

            Daftar Kandidat
          </b-button>
        </div>
        <div
          id="wrapper-image"
          :style="candidate && candidate.photo ? `background-image: url('${candidate.photo}detail-kandidat.png')` : ''"
        />
        <b-card
          v-if="candidate"
          id="wrapper-content"
          no-body
          class="p-1"
        >
          <div
            id="content-head"
            class="text-center"
          >
            <h1
              class="text-uppercase text-center"
              style="color: #1B1B1B;"
            >
              {{ candidate.nama }}
            </h1>
          </div>
          <div>
            <b-tabs
              justified
              active-nav-item-class="nav-tab-custom"
            >
              <b-tab
                active
                title="Visi Misi"
              >
                <b-card-text>
                  <!-- eslint-disable vue/no-v-html -->
                  <h3 class="title-content">
                    Visi
                  </h3>
                  <div v-html="candidate.visi" />
                  <h3 class="title-content">
                    Misi
                  </h3>
                  <div v-html="candidate.misi" />
                </b-card-text>
              </b-tab>
              <b-tab
                title="Riwayat"
              >
                <b-card-text>
                  <div v-html="candidate.riwayat" />
                </b-card-text>
              </b-tab>
              <b-tab title="MedSos">
                <b-card-text>
                  <div v-html="candidate.narahubung" />
                </b-card-text>
              </b-tab>
            </b-tabs>
          </div>
        </b-card>
      </div>
    </div>
    <ButtonLogoutVue />
  </div>
</template>

<script>
import {
  BButton, BCard, BTab, BCardText, BTabs,
} from 'bootstrap-vue'
import API from '@/api'
import ButtonLogoutVue from '@/components/ButtonLogout/ButtonLogout.vue'

export default {
  components: {
    BButton,
    BCard,
    BTab,
    BCardText,
    BTabs,
    ButtonLogoutVue,
  },
  data() {
    return {
      candidates: {
        data: [],
        fetching: 'idle',
      },
    }
  },
  computed: {
    candidate() {
      const id = this.$route.params.candidateId
      const findIndex = this.candidates.data.findIndex(item => item.id === +id)
      return this.candidates.data[findIndex] || null
    },
  },
  created() {
    this.fetchCandidate()
  },
  methods: {
    async fetchCandidate() {
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        this.candidates.fetching = 'pending'
        const { data } = await API.evote.publicCandidates()
        this.candidates.data = data
        this.candidates.fetching = 'resolved'
      } catch (error) {
        console.log(error)
        this.candidates.fetching = 'rejected'
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
      }
    },
  },
}
</script>

<style>
  [dir=ltr] .nav-tabs .nav-link:after {
    background: #FEDC00 !important;
  }

  .nav-tab-custom {
    color: #1B1B1B !important;
  }

</style>
<style lang="scss" scoped>
  @media (max-width: 640px) {
    .mobile-size {
      width: 100vw !important;
    }
  }
  .mobile-size {
    width: 360px;
    height: 100vh;
    background-color: #F9F9F9;
    overflow-y: auto;
  }

  #wrapper-mobile-size {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #E5E5E5;
  }
  #detail-candidate {
    // position: relative;
    #btn-back button {
      border-radius: 0.625rem;
      font-size:  0.875rem !important;
      line-height: 1.375rem;
      margin-top: 1.375rem;
      margin-left: 1.375rem;
      padding: 0.25rem;
      align-items: center;
      display: flex;
    }
  }
  #wrapper-image {
    height: 22.5rem;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
  #wrapper-content {
    top: -1rem;
    border-radius: 1rem;
  }

  #content-head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    span {
      font-weight: 500;
    }
  }
  .title-content {
    font-size: 1rem;
    font-weight: 700;
  }

</style>
